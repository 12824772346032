<template>
  <div class="container menu-header-common">
    <ul>
      <li>
        <!-- <router-link to="/invite-friend" :class="{'active': $route.name === 'InviteFriend', 'colorPink': colorPink}" class="item">
          <div class="item__content">
            <span class="ico">
              <img v-if="colorPink" src="../assets/images/icon/ico_apply_pink.svg" alt="応募する">
              <img v-else-if="$route.name === 'InviteFriend'" src="../assets/images/icon/ico_apply_active.svg" alt="応募する">
              <img v-else src="../assets/images/icon/ico_apply.svg" alt="応募する">
            </span>
            <span>応募する</span>
            <div class="border-b-4"></div>
          </div>
        </router-link> -->
        <router-link :to="{
          name: 'CategoryList.Category',
          params: { id: '520d7697-604e-11ef-9b8b-0a03d66a138d', title: '' },
          query: { typeColor: '1', level: 'mid' },
        }" :class="{ active: $route.name === 'CategoryList.Category' && $route.params.id === '520d7697-604e-11ef-9b8b-0a03d66a138d' }" class="item">
          <div class="item__content">
            <span class="ico">
              <img v-if="$route.name === 'CategoryList.Category' && $route.params.id === '520d7697-604e-11ef-9b8b-0a03d66a138d'" src="../assets/images/icon/ico_apply_active.svg"
                alt="応募する" />
              <img v-else src="../assets/images/icon/ico_apply.svg" alt="応募する" />
            </span>
            <span>応募する</span>
            <div class="border-b-4"></div>
          </div>
        </router-link>
      </li>

      <li>
        <router-link :to="{
          name: 'CategoryList.Category',
          params: { id: '521b734d-604e-11ef-9b8b-0a03d66a138d', title: '' },
          query: { typeColor: '1', level: 'mid' },
        }" :class="{ active: $route.name === 'CategoryList.Category' && $route.params.id === '521b734d-604e-11ef-9b8b-0a03d66a138d' }" class="item">
          <div class="item__content">
            <span class="ico">
              <img v-if="$route.name === 'CategoryList.Category' && $route.params.id === '521b734d-604e-11ef-9b8b-0a03d66a138d'" src="../assets/images/icon/ico_look_active.svg" alt="見る" />
              <img v-else src="../assets/images/icon/ico_look.svg" alt="見る" />
            </span>
            <span>見る</span>
            <div class="border-b-4"></div>
          </div>
        </router-link>
      </li>

      <li>
        <router-link :to="{
          name: 'CategoryList.Category',
          params: { id: 'point', title: '' },
          query: { typeColor: '1', level: 'point' },
        }" :class="{ active: $route.name === 'CategoryList.Category' && $route.params.id === 'point' }" class="item">
          <div class="item__content">
            <span class="ico">
              <img v-if="$route.name === 'CategoryList.Category' && $route.params.id === 'point'" src="../assets/images/icon/ico_point_active.svg"
                alt="毎日ポイント" />
              <img v-else src="../assets/images/icon/ico_point.svg" alt="毎日ポイント" />
            </span>
            <span>毎日ポイント</span>
            <div class="border-b-4"></div>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ListMenu',

  computed: {
    ...mapState('user', ['flagModalSearch'])
  },

  data () {
    return {
      colorPink: false
    }
  },

  created () {
    if (this.$route.query.typeColor) {
      this.colorPink = this.$route.query.typeColor === '2'
    }
  },

  watch: {
    '$route.query.typeColor': {
      handler () {
        this.colorPink = this.$route.query.typeColor === '2'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$sp: 780px;

@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

$viewport: 780;

@function pv($num, $width: $viewport) {
  @return calc(1vw * $num * 100 / $width);
}

.menu-header-common {
  font-family: $font-family-title;
  padding: 15px 0;

  ul {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
  }

  li {
    flex-basis: 33.3%;
    border-left: 1px dotted #707070;
    border-right: 1px dotted #707070;
    text-align: center;
    font-size: 24px;
    line-height: 1;
    padding: 5px 0;

    a {
      text-decoration: none;
      color: #282828;
      display: flex;
      justify-content: center;
      align-items: center;

      .ico {
        margin-right: 8px;
      }
    }
  }

  li:nth-child(2) {
    border-left: none;
  }

  li:nth-child(3) {
    border-left: none;
  }

  .item {
    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      .border-b-4 {
        display: none;
        position: absolute;
        bottom: -10px;
        left: 0;
        right: 0;
        height: 4px;
      }
    }
  }

  .item.active {
    font-family: $font-family-title-B;
    color: $default-green;
    transition: 0.5s all;

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .border-b-4 {
      display: block;
      background: rgba($default-green, 0.5);
    }

    @media #{$info-phone} {
      border-bottom: 0;
    }

    path {
      fill: $default-green;
    }

    // type color 2
    &.colorPink {
      color: #a54b67;

      .item__content {
        .border-b-4 {
          display: block;
          background: rgba(#a54b67, 0.5);
        }
      }

      path {
        fill: #a54b67;
      }
    }
  }
}

@media #{$info-phone} {
  .menu-header-common {
    padding: 2vw 0;

    ul {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
    }

    li {
      flex-basis: 33.3%;
      border-left: 1px dotted #707070;
      border-right: 1px dotted #707070;
      text-align: center;
      font-size: pv(24);
      line-height: 1;
      padding: 1vw 0;

      a {
        text-decoration: none;
        color: #282828;
        display: flex;
        justify-content: center;
        align-items: center;

        .ico {
          margin-right: 1vw;

          img {
            max-height: 3.5vw;
          }
        }
      }
    }

    li:nth-child(1) {
      border-left: none;
    }

    li:nth-child(2) {
      border-left: none;
    }

    li:nth-child(3) {
      border-right: none;
    }
  }
}
</style>
