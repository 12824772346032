import { debounce } from 'lodash-es'

export default {
  data () {
    return {
      window: {
        width: window.innerWidth,
        height: window.innerHeight
      }
    }
  },

  mounted () {
    window.addEventListener('resize', debounce(this.onResize, 300))
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    onResize () {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    }
  }
}
