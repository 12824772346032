<template>
  <div class="modal-search">
    <!-- Modal content -->
    <div class="wrap-modal">
      <div class="content-modal">
        <span class="close-modal"
              @click="closeModal">
        <img src="../assets/images/icon/icon-close.svg" alt="icon close">
      </span>
        <!-- title block search -->
        <span class="sub-title search-title">検索</span>

        <!-- input search -->
        <div class="btn-search">
          <img src="../assets/images/icon/icon-search.png" alt="icon-search">
          <input v-model="searchValue" type="text" placeholder="キーワードを入力">
          <button @click="handleSearch">検索</button>
        </div>

        <!-- title block filter -->
        <span class="sub-title">絞り込み</span>

        <!-- list category -->
        <Sidebar class="sidebar-modal"/>
      </div>

    </div>
  </div>
</template>

<script>
import Sidebar from '@/layouts/Sidebar'
import Dimensions from '@/mixins/dimensions.mixin'

import { mapState } from 'vuex'

export default {
  name: 'ModalSearch',

  components: { Sidebar },

  mixins: [Dimensions],

  data () {
    return {
      searchValue: ''
    }
  },

  computed: {
    ...mapState('category', ['categoryListTitle'])
  },

  methods: {
    closeModal () {
      this.$emit('closeModal')
    },

    handleSearch () {
      this.$router.replace({
        name: 'CategoryList.Search',
        query: { keyword: this.searchValue }
      }).catch(() => {})

      // turn off modal
      this.$store.commit('user/DISPLAY_MODAL')
    },

    setHeightModal () {
      const wrapModalEl = document.querySelector('.wrap-modal')
      const contentModalEl = document.querySelector('.content-modal')
      if (!wrapModalEl || !contentModalEl) return

      wrapModalEl.style.maxHeight = `calc(${this.window.height}px - 52px - 6vw)`
      contentModalEl.style.maxHeight = `calc(${this.window.height}px - 89px - 6vw)`
    }
  },

  mounted () {
    this.setHeightModal()
  },

  watch: {
    'window.height': {
      handler () {
        this.setHeightModal()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-search {
  display: none;
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
  @media #{$info-phone} {
    display: block;
  }
  .wrap-modal {
    position: relative;
    max-height: calc(100vh - 52px - 6vw);
    top: 9px;
    padding: 18px;
    background: #ffffff;
    max-width: calc(100% - 18px);
    margin: auto;
    border-radius: 6px;
    &:after {
      content: '';
      position: absolute;
      width: 16px;
      height: 8px;
      clip-path: polygon(50% 0%, 100% 0, 50% 100%, 0 0);
      background: #ffffff;
      left: calc(30% - 11px);
      bottom: -8px;
      z-index: 20;
    }
    .content-modal {
      position: relative;
      margin: auto;
      width: 100%;
      max-height: calc(100vh - 100px);
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      .close-modal {
        position: fixed;
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 12px;
        top: 12px;
        cursor: pointer;
        z-index: 1;
      }
      h2 {
        font-family: $font-family-title;
        font-size: 36px;
        font-weight: normal;
        -webkit-text-stroke: 1px #282828;
        color: #ffffff;
        text-align: center;
        padding: 23px 0 0;
      }
      .sub-title {
        font-family: $font-family-title;
        font-size: 23px;
        display: block;
        text-align: center;
        line-height: 1;
        margin-bottom: 33px;
      }
      .search-title {
        font-size: 25px;
      }
      .btn-search {
        position: relative;
        display: flex;
        align-items: center;
        height: 44px;
        width: 100%;
        background: #F5F5F5;
        border-radius: 22px;
        padding: 7px 64px 7px 46px;
        margin-bottom: 30px;
        img {
          position: absolute;
          left: 18px;
          top: 50%;
          transform: translateY(-50%);
          width: 16px;
        }
        input {
          width: 100%;
          background: #F5F5F5;
          color: #888888;
        }
        button {
          font-family: $font-family-title;
          position: absolute;
          right: 3px;
          top: 3px;
          line-height: 1;
          padding: 11px;
          background: #73483E;
          border-radius: 19px;
          color: #ffffff;
          cursor: pointer;
          letter-spacing: 2px;
        }
      }
      .sidebar-modal {
        max-height: unset;
      }
    }
  }

}
</style>
