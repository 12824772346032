<template>
  <div class="sp">
    <div id="footerMenu" class="global_menu">
      <ul>
        <li class="btn">
          <router-link to="/" :class="{ 'active': $route.name === 'Index' }" class="item">
            <div class="global_ico">
              <img v-if="$route.name === 'Index'" src="../assets/images/icon/sp_foot_ico01_active.svg" alt="応募する">
              <img v-else src="../assets/images/icon/sp_foot_ico01.svg">
            </div>
            <span>HOME</span>
          </router-link>
        </li>

        <li class="btn">
          <div class="item" :class="{ 'active': flagModalSearch }" @click="toggleSearch">
            <div class="global_ico">
              <img v-if="flagModalSearch" src="../assets/images/icon/sp_foot_ico02_active.svg" alt="検索">
              <img v-else src="../assets/images/icon/sp_foot_ico02.svg" alt="検索">
            </div>
            <span>検索</span>
          </div>
        </li>

        <li class="btn">
          <router-link :to="{
            name: 'CategoryList.Category',
            params: { id: '520d7697-604e-11ef-9b8b-0a03d66a138d', title: '' },
            query: { typeColor: '1', level: 'mid' },
          }" :class="{ 'active': $route.name === 'CategoryList.Category' && $route.params.id === '520d7697-604e-11ef-9b8b-0a03d66a138d' }" class="item">
            <div class="global_ico">
              <img v-if="$route.name === 'CategoryList.Category' && $route.params.id === '520d7697-604e-11ef-9b8b-0a03d66a138d'" src="../assets/images/icon/sp_foot_ico03_active.svg"
                alt="応募">
              <img v-else src="../assets/images/icon/sp_foot_ico03.svg" alt="応募">
            </div>
            <span>応募</span>
          </router-link>
        </li>

        <li class="btn">
          <router-link :to="{
            name: 'CategoryList.Category',
            params: { id: '521b734d-604e-11ef-9b8b-0a03d66a138d', title: '' },
            query: { typeColor: '1', level: 'mid' },
          }" :class="{ 'active': $route.name === 'CategoryList.Category' && $route.params.id === '521b734d-604e-11ef-9b8b-0a03d66a138d' }" class="item">
            <div class="global_ico">
              <img v-if="$route.name === 'CategoryList.Category' && $route.params.id === '521b734d-604e-11ef-9b8b-0a03d66a138d'" src="../assets/images/icon/sp_foot_ico04_active.svg" alt="見る">
              <img v-else src="../assets/images/icon/sp_foot_ico04.svg" alt="見る">
            </div>
            <span>見る</span>
          </router-link>
        </li>

        <li class="btn">
          <router-link :to="{ name: 'Profile.Mypage' }" :class="{ 'active': $route.name === 'Profile.Mypage' }"
            class="item">
            <div class="global_ico">
              <img v-if="$route.name === 'Profile.Mypage'" src="../assets/images/icon/sp_foot_ico05_active.svg"
                alt="マイページ">
              <img v-else src="../assets/images/icon/sp_foot_ico05.svg" alt="マイページ">
            </div>
            <span>マイページ</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'FooterMenu',

  computed: {
    ...mapState('user', ['flagModalSearch'])
  },

  // data () {
  //   return {
  //     colorPink: false
  //   }
  // },

  // created () {
  //   if (this.$route.query.typeColor) {
  //     this.colorPink = this.$route.query.typeColor === '2'
  //   }
  // },

  // watch: {
  //   '$route.query.typeColor': {
  //     handler () {
  //       this.colorPink = this.$route.query.typeColor === '2'
  //     }
  //   }
  // },

  methods: {
    toggleSearch () {
      this.$emit('toggleSearch')
    }
  }
}
</script>

<style lang="scss" scoped>
$sp: 780px;

@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

$viewport: 780;

@function pv($num, $width: $viewport) {
  @return calc(1vw * $num * 100 / $width);
}

.global_menu {
  font-family: $font-family-title;
  position: fixed;
  z-index: 30;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  border-radius: pv(10) pv(10) 0 0;
  padding: 2vw 0 calc(2vw - 4px);

  ul {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  li {
    flex-basis: 20%;
    text-align: center;
    border-right: 1px dotted #707070;

    .global_ico {
      max-width: 5vw;
      margin: 0 auto calc(1vw - 2px);
    }

    span {
      font-size: pv(20);
      line-height: 1;
      text-align: center;
      color: #282828;
    }
  }

  li:last-child {
    border: none;
  }

  svg {
    width: 100%;
    height: auto;
  }

  .item.active {
    font-family: $font-family-title-B;
    transition: 0.5s all;

    span {
      color: $default-green;
    }

    // @media #{$info-phone} {
    //   border-bottom: 0;
    // }
  }
}
</style>
