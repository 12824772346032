<template>
  <footer id="footer">
    <!-- footer top -->
   <div class="header-footer container">
     <!-- list category -->
     <div class="list-cate">
       <div class="block-category"
            v-for="(itemBig, index) in categoryListFooter"
            :key="index">
         <!-- category big -->
          <h3>{{ itemBig.title }}</h3>

         <!-- wrap category middle and small -->
         <div class="wrap-list-middle"
              v-if="itemBig.children.length > 0">
           <div v-for="(itemMid, index) in itemBig.children"
                :key="index"
                class="block-cate-middle">
             <!-- category middle -->
             <div class="title-middle" v-for="mid in itemMid" :key="mid.title">
               <p class="middle-english">{{ displayEnglishName(mid.title) }}</p>
               <p class="middle-japan">{{ mid.title }}</p>
            <!-- category small -->
                <ul>
                    <li
                        v-for="(itemSmall) in mid.children"
                        :key="itemSmall.id">
                        <span>{{ displayNumberItem(itemSmall.index, 3) }}</span>
                        <p @click="handleLink(itemSmall.id, itemBig.order)">{{ itemSmall.title }}</p>
                    </li>
                </ul>
             </div>
           </div>
         </div>
       </div>
     </div>

     <!-- contact -->
    <div class="contact">
      <div class="block-content">
        <h4>ABOUT</h4>
        <p>このサイトについて</p>
        <ul>
          <li><router-link to="/guide">ご利用ガイド</router-link></li>
          <li><router-link to="/exchange-point">ポイント交換先</router-link></li>
          <li><router-link to="/help">ヘルプ</router-link></li>
          <li><a href="https://www.exc-dmk.co.jp/about/data.html" target="_blank">会社概要</a></li>
          <li><router-link to="/about-advertising">広告掲載について</router-link></li>
          <li><router-link to="/contact">ご意見・ご要望・お問い合わせ</router-link></li>
        </ul>
      </div>
      <div class="block-content mb-0">
        <h4>SNS</h4>
        <p>公式アカウント</p>
        <ul class="list-social">
          <li><a href="https://www.instagram.com/chibijob.official/" target="_blank">
            <img src="../assets/images/icon/icon-instagram-footer.svg" alt=""></a>
          </li>
          <li><a href="https://twitter.com/chibijob" target="_blank">
            <img src="../assets/images/icon/icon-twitter-footer.svg" alt=""></a>
          </li>
          <li><a href="https://lin.ee/nyWu2VV" target="_blank">
            <img src="../assets/images/icon/icon-line-footer.svg" alt=""></a>
          </li>
        </ul>
      </div>
        <!-- QR, Banner Download App -->
     <div class="block-content mb-0 download-app-container" v-if="checkMobile()">
          <picture>
            <source media="(min-width:768px)" srcset="../assets/images/dmk_chibi-job_bnr.png 1x,../assets/images/dmk_chibi-job_bnr@2x.png 2x">
            <source media="(max-width:767px)" srcset="../assets/images/dmk_chibi-job_bnr@2x.png">
            <img src="../assets/images/dmk_chibi-job_bnr.png" alt="CHIBI JOBとは？" class="switching image-chibijob">
          </picture>
          <p class="content-download mb-0">＼公式アプリを 今すぐダウンロード／</p>
          <div class="img-store-container">
              <div class="store-item" @click="openApp">
                <picture>
                  <source media="(min-width:768px)" srcset="../assets/images/btn-google.png 1x,../assets/images/btn-google@2x.png 2x">
                  <source media="(max-width:767px)" srcset="../assets/images/btn-google@2x.png">
                  <img src="../assets/images/btn-google.png" alt="CHIBI JOBとは？" class="switching">
                </picture>
                <div class="text-center mt-3 qr-store">
                  <picture>
                    <source media="(min-width:768px)" srcset="../assets/images/google.png 1x,../assets/images/google@2x.png 2x">
                    <img src="../assets/images/google.png" alt="CHIBI JOBとは？" class="switching">
                  </picture>
                </div>
              </div>
              <div class="store-item" @click="openApp">
                <picture>
                  <source media="(min-width:768px)" srcset="../assets/images/btn-app.png 1x,../assets/images/btn-app@2x.png 2x">
                  <source media="(max-width:767px)" srcset="../assets/images/btn-app@2x.png">
                  <img src="../assets/images/btn-app.png" alt="CHIBI JOBとは？" class="switching">
                </picture>
                <div class="text-center mt-3 qr-store">
                  <picture>
                    <source media="(min-width:768px)" srcset="../assets/images/app.png 1x,../assets/images/app@2x.png 2x">
                    <img src="../assets/images/app.png" alt="CHIBI JOBとは？" class="switching">
                  </picture>
                </div>
              </div>
          </div>
      </div>
    </div>
   </div>

    <!-- footer copyright  -->
    <div class="footer-bottom container">
      <div class="link-other">
        <router-link :to="{ name: 'Policy' }">個人情報の取扱いについて</router-link>

        <router-link :to="{ name: 'Term' }">利用規約</router-link>
      </div>
      <div>
        <p>Copyright ©︎ 2022 ChibiJob, All Rights Reserved.</p>
        <p class="text-right-footer">R2事業再構築</p>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'
import Common from '@/mixins/common.mixin'

export default {
  name: 'Footer',
  mixins: [Common],

  computed: {
    ...mapState('category', ['categoryListTitle', 'categoryListFooter'])
  },

  methods: {
    displayNumberItem (num, places) {
      const numFormat = String(num).padStart(places, '0')
      return numFormat
    },

    displayEnglishName (japanName) {
      let name = ''

      switch (japanName) {
        case 'トピックで探す':
          name = 'TOPIC'
          break
        case '無料':
          name = 'FREE'
          break
        case 'クレジットカード・キャッシング':
          name = 'CARD'
          break
        case '金融・投資・保険':
          name = 'FINANCE'
          break
        case 'エンタメ':
          name = 'ENTERTAINMENT'
          break
        case '生活・お役立ち':
          name = 'LIFE'
          break
        case 'アプリ':
          name = 'APP'
          break
        case 'ショッピング':
          name = 'SHOPPING'
          break
        case '旅行':
          name = 'TRAVEL'
          break
      }

      return name
    },

    handleLink (id, typeColor) {
      let nameRoute = this.$route.name
      if (nameRoute !== 'CategoryList.Survey' && nameRoute !== 'CategoryList.Receipt') {
        nameRoute = 'CategoryList.Category'
      }
      this.$router.push({
        name: nameRoute,
        params: { id: id },
        query: { typeColor: String(typeColor) }
      }).catch(() => {})
    },
    openApp () {
      window.open(process.env.VUE_APP_UNIVERSAL_LINK)
    }
  }
}
</script>

<style lang="scss" scoped>
footer {
  background: #BDBFAE;
  overflow: hidden;

  @media #{$info-phone} {
    padding-bottom: calc(52px - 6vw);
  }

  .header-footer {
    display: flex;
    align-items: flex-start;
    .list-cate {
      width: 70%;
      display: flex;
      padding: 60px 0 30px;
      position: relative;
      @media #{$info-phone} {
        display: none;
      }
      &:after {
        content: '';
        position: absolute;
        width: 1px;
        height: 100%;
        top: 0;
        right: 0;
        border-left: 1px dotted #605E58;
      }
      .block-category {
        /* width: 33.3%; */
        width: 100%;
        /* &:nth-child(2) {
          flex: 1;
          .wrap-list-middle {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            .block-cate-middle {
              width: 50%;
            }
          }
        } */
        h3 {
          font-family: $font-family-title;
          margin-bottom: 30px;
          @include font-size(20px);
          font-weight: normal;
        }
        .wrap-list-middle {
            /* display: flex; */
          .block-cate-middle {
            margin-bottom: 40px;
            display: flex;
            .title-middle {
              width: 33.3%;
              font-family: $font-family-title;
              p.middle-english {
                word-break: break-all;
                @include font-size(23px);
                color: #ffffff;
                font-family: $font-family-title-B;
              }
              p.middle-japan {
                @include font-size(16px);
                padding-bottom: 13px;
                position: relative;
                margin-bottom: 16px;
                &:after {
                  content: '';
                  position: absolute;
                  width: 15px;
                  height: 3px;
                  background: #CACBC6;
                  left: 0;
                  bottom: 0;
                }
              }
              @media #{$info-tablet-vertical} {
                &:nth-child(3n + 3) {
                  padding-left: 5px;
                }
              }
            }
            ul {
              transform: translateX(-30px);
              @media  #{$info-laptop-normal} {
                transform: translateX(0);
              }
              li {
                display: flex;
                align-items: center;
                @include font-size(14px);
                line-height: 1.2;
                margin-bottom: 20px;
                &:last-child {
                  margin-bottom: 0;
                }
                p {
                  cursor: pointer;
                  transition: .2s;
                  word-break: break-all;
                  font-family: $font-label;

                  &:hover {
                    text-decoration: underline;
                    transition: .2s;
                  }
                }
                span {
                  font-family: $font-label;
                  @include font-size(12px);
                  color: #ffffff;
                  margin-right: 12px;
                }
              }
            }
          }
        }
      }
    }
    .contact {
      padding: 122px 0 60px 71px;
      width: 30%;
      @media #{$info-tablet-horizontal} {
        padding: 122px 0 30px 30px;
      }
      @media #{$info-phone} {
        width: 100%;
        padding: 44px 0;
      }
    }
    .block-content {
      margin-bottom: 40px;
       h4 {
         font-family: $font-family-title-B;
         @include font-size(23px);
         color: #ffffff;
         @media #{$info-phone} {
           font-size: 20px;
         }
       }
      p {
        @include font-size(16px);
        font-family: $font-family-title;
        padding-bottom: 13px;
        position: relative;
        margin-bottom: 16px;
        @media #{$info-phone} {
          font-size: 20px;
        }
        &:after {
          content: '';
          position: absolute;
          width: 15px;
          height: 3px;
          background: #CACBC6;
          left: 0;
          bottom: 0;
        }
      }
      ul {
        @include font-size(14px);
        li {
          line-height: 1.2;
          margin-bottom: 20px;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
          @media #{$info-phone} {
            font-size: 16px;
            line-height: 1;
          }
          a {
            color: #282828;
          }
        }
        &.list-social {
          display: flex;
          align-items: center;
          li {
            margin-right: 12px;
            margin-bottom: 0;
            width: 32px;
            height: 32px;
            @media #{$info-phone} {
              width: 44px;
              height: 44px;
            }
            img {
               width: 100%;
            }
          }
        }
      }
      &.download-app-container {
        margin-top: 50px;
        .content-download {
          line-height: 28px;
          font-size: 15px;
          // font-family: 'A P-OTF A1Gothic Std, M';
          position: unset;
          text-align: center;
          padding-bottom: 6px;
          margin-top: 20px;
          @media #{$info-tablet-horizontal} {
            font-size: 13px;
          }
          @media #{$info-tablet-vertical} {
            font-size: 10px;
          }
          @media #{$info-phone} {
            font-size: 18px;
          }
        }
        .img-store-container {
          display: flex;
          justify-content: space-between;
          .store-item {
            max-width: 48%;
          }
          .qr-store {
            @media #{$info-phone} {
              display: none
            }
          }
        }
        .image-chibijob {
          width: 100%;
        }
      }
    }
  }
  .footer-bottom {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 60px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: -100%;
      left: -100%;
      height: 1px;
      right: -100%;
      top: 0;
      background: #615954;

    }
    @media #{$info-tablet-vertical} {
      padding: 20px 30px 40px;
    }
    @media #{$info-phone} {
      flex-direction: column;
      padding: 45px 20px 87px;
      align-items: flex-start;
    }
    .link-other {
      display: flex;
      padding-top: 2px;
      @media #{$info-phone} {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 30px;
        padding-top: 0;

      }
      a {
        color: #282828;
        margin-right: 40px;
        @include font-size(14px);
        @media #{$info-phone} {
          font-size: 16px;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
    p {
      @include font-size(12px);
    }
    .text-right-footer {
      text-align: right;
      @media #{$info-phone} {
        text-align: left;
      }
    }
  }
}
</style>
