import { ApiService } from './api.service'
import { getOneTimeParams } from '@/utils/helpers'

const BaseUrl = '/advertises'

const payload = (params) => ({
  ...params,
  ...getOneTimeParams()
})

export function getListAdvertises (params = {}) {
  return ApiService.get(`${BaseUrl}`, payload(params)).then(resp => resp)
}

export function getListRecommended (id, params = {}) {
  return ApiService.get(`${BaseUrl}/${id}/recommended-advertises`, payload(params)).then(resp => resp)
}

export function show (id, params = {}) {
  return ApiService.get(`${BaseUrl}/${id}`, payload(params)).then(resp => resp)
}

export function getListRecentSeen (params = {}) {
  return ApiService.get(`${BaseUrl}/list/viewed`, payload(params)).then(resp => resp)
}

export function getListAdvertisesNew (params = {}) {
  return ApiService.get(`${BaseUrl}/news`, payload(params)).then(resp => resp)
}

// export function getListAdvertisesByMid (id, params = {}) {
//   return ApiService.get(`${BaseUrl}/mid-categories?${id}/advertises`, params).then(resp => resp)
// }

export function getListRecommendModal (params = {}) {
  return ApiService.get(`${BaseUrl}/list-recommended`, payload(params)).then(resp => resp)
}

export function getAdsOfGetPointList (params = {}) {
  return ApiService.get(`${BaseUrl}/get-points`, payload(params)).then((resp) => resp)
}
